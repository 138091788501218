import { createSlice } from '@reduxjs/toolkit';
import { HYBRID, ICON_PLANE, ICON_ATC, DARK, TAG_NONE, TAG_ATC } from 'components/common/LeafletMap/baseLayers';
import { mapDefaultViewport, zoomDiameterToBounds } from 'utils/mapUtils';
import { faaCategoryOptions } from 'utils/formFieldsHelpers';
import { fetchUser } from './auth';

const DEFAULT_MAP_TYPE = HYBRID;
const DEFAULT_ICON_TYPE = ICON_PLANE;
const DEFAULT_TAG_TYPE = TAG_NONE;

const initialFilters = {
  reg: {
    alias: 'Registration',
    value: [''],
    placeholder: ['ABCD, N65, N6, N'],
    isWildcard: true,
  },
  bsd: {
    alias: 'Based',
    value: [''],
    placeholder: ['KXXX'],
    isWildcard: true,
  },
  typ: {
    alias: 'Type',
    value: [''],
    placeholder: ['A320'],
    isWildcard: true,
  },
  faa: {
    alias: 'FAA Category',
    value: [''],
    options: [{ value: 'any', name: 'Any' }, ...faaCategoryOptions],
  },
  own: {
    alias: 'Owner',
    value: [''],
    placeholder: ['Owner'],
    isWildcard: true,
  },
  opr: {
    alias: 'Operator',
    value: [''],
    divider: true,
    placeholder: ['Operator'],
    isWildcard: true,
  },
  fli: {
    alias: 'Flight',
    value: [''],
    placeholder: ['ABCD123'],
    isWildcard: true,
  },
  squ: {
    alias: 'Squawk',
    value: [''],
    placeholder: ['7700'],
    isWildcard: true,
  },
  spd: {
    alias: 'Speed',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  alt: {
    alias: 'Altitude',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  vrt: {
    alias: 'Vertical Rate',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  altg: {
    alias: 'GNSS Alt.',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  cla: {
    alias: 'cla parameter',
    value: [''],
    placeholder: [''],
    isDebugFilter: true,
  },
  ict: {
    alias: 'Aircraft Category',
    value: [''],
    placeholder: ['HEL'],
    isDebugFilter: true,
  },
  gda: {
    alias: 'AIR/GND',
    value: [''],
    options: [
      { value: 'any', name: 'Any' },
      { value: 'A', name: 'AIR' },
      { value: 'G', name: 'GND' },
    ],
  },
};

/**
 * @typedef {Object} MapState
 * @property {object} controls - Contains name of the control and it's state true/false
 * @property {string} mapType - Type of the base layer on of the baseLayers
 * @property {string} mapIcons - Type of the icons drawn on the map (plane|atc)
 * @property {boolean} tagsLayer - Overlay with live flight info tags
 * @property {boolean} sensorsLayer - Overlay with sensors
 * @property {boolean} weatherLayer - Overlay with weather
 * @property {(number[][]|null)} zoomBounds - One of the zoom presets values
 * @property {{center: number[], zoom: number, bounds: {vpn: number, vps: number, vpe: number, vpw: number}}} viewport ReactLeaflet viewport
 */
const initialState = {
  controls: {
    layers: false,
    flights: false,
    filters: false,
    zoomPreset: false,
  },
  mapType: DEFAULT_MAP_TYPE,
  iconType: DEFAULT_ICON_TYPE,
  tagType: DEFAULT_TAG_TYPE,
  mapWeatherRadarType: null,
  attributes: {
    tagsLayer: false,
    turnedOffLayer: false,
    sensorsLayer: false,
    weatherLayer: false,
    geofenceLayer: false,
    airportsLayer: false,
    airspacesLayer: false,
    dimmingLayer: false,
  },
  sources: {
    a: true,
    u: true,
    m: true,
    c: true,
    f: false,
    t: false,
  },
  origins: {
    VT: true,
    UAX: true,
  },
  accordionState: {
    accordion_map: true,
    accordion_layer: true,
    accordion_target: true,
    accordion_weather: false,
    accordion_tags: false,
    accordion_source: false,
  },
  zoomBounds: null,
  viewport: mapDefaultViewport,
  filters: initialFilters,
  isBasicMap: false,
};

export const mapSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    toggleControl(state, action) {
      state.controls[action.payload] = !state.controls[action.payload];
    },
    resetControls(state) {
      state.controls = {};
    },
    setMapType(state, action) {
      state.mapType = action.payload;
    },
    setWeatherRadarType(state, action) {
      const newWeatherRadarType = action.payload;
      if (state.mapWeatherRadarType === newWeatherRadarType) {
        state.mapWeatherRadarType = null;
      } else {
        state.mapWeatherRadarType = action.payload;
      }
    },
    setIconType(state, action) {
      if (action.payload === ICON_ATC) {
        state.tagType = TAG_ATC;
        state.mapType = DARK;
      }
      if (action.payload === ICON_PLANE && state.tagType === TAG_ATC) {
        state.tagType = TAG_NONE;
      }
      state.iconType = action.payload;
    },
    setTagType(state, action) {
      state.tagType = action.payload;
    },
    setZoomBounds(state, action) {
      state.zoomBounds = action.payload;
    },
    setViewport(state, action) {
      state.viewport = action.payload;
    },
    toggleLayer(state, action) {
      const { payload: layerName } = action;
      state.attributes[layerName] = !state.attributes[layerName];
    },
    setLayerState(state, action) {
      const {
        payload: { layerName, flag },
      } = action;
      state.attributes[layerName] = flag;
    },
    makeUnavailableLayer(state, action) {
      const { payload: layerName } = action;
      state.attributes[layerName] = null;
    },
    toggleSource(state, action) {
      state.sources[action.payload] = !state.sources[action.payload];
    },
    toggleOrigin(state, action) {
      state.origins[action.payload] = !state.origins[action.payload];
    },
    setSources(state, action) {
      const newSources = action.payload;
      state.sources = { ...state.sources, ...newSources };
    },
    setCenter(state, action) {
      state.viewport.center = action.payload;
    },
    setFilter(state, action) {
      const {
        payload: { filter, valueIndex, value },
      } = action;
      state.filters[filter].value[valueIndex] = value;
    },
    resetFilters(state) {
      state.filters = initialFilters;
    },
    toggleModalAccordion(state, action) {
      const { payload } = action;
      state.accordionState[payload.name] = payload.state;
    },
    setIsBasicMap(state, action) {
      state.isBasicMap = action.payload;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      const { airport } = action.payload;
      const { lat, lon } = airport;
      state.viewport.center = [+lat, +lon];
      state.zoomBounds = zoomDiameterToBounds(airport.zoomDiameter2, +lat, +lon);
    },
  },
});

export default mapSlice.reducer;

export const {
  toggleLayer,
  setLayerState,
  toggleControl,
  setMapType,
  setWeatherRadarType,
  setIconType,
  resetControls,
  setZoomBounds,
  setViewport,
  toggleSource,
  setSources,
  setCenter,
  setFilter,
  resetFilters,
  setTagType,
  toggleModalAccordion,
  setIsBasicMap,
  makeUnavailableLayer,
  toggleOrigin,
} = mapSlice.actions;
