import React, { Suspense, lazy, useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import clsx from 'clsx';
import { Map, TileLayer, LayersControl, ScaleControl, WMSTileLayer, Rectangle } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { getPlainBounds } from 'utils/mapUtils';
import { NoiseAbatementPropType } from 'utils/commonPropTypes';
import { makeUnavailableLayer } from 'redux/modules/map';
import Noise from './components/Noise';
import {
  baseLayers,
  baseLayersToGoogleMutantType,
  TERRAIN,
  ROADMAP,
  SATELLITE,
  HYBRID,
  OSM,
  DARK,
  IFRL,
  IFRH,
  VFR,
  weatherRadarLayerDetailsList,
} from './baseLayers';
import WeatherRadar from './components/WeatherRadar';

import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';

const OpenAIPMapboxLayer = lazy(() => import('./components/OpenAIPMapboxLayer'));
const MapBoxGLLayer = lazy(() => import('./components/MapBoxGLLayer'));

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic2NobWl0dGZ1bWlhbiIsImEiOiJja2l1MHg0cGIwaW4zMnBtdXVvcWJuMHZlIn0.VNdDi5xH33W2JCd3TG1ZcA';
// NOTE! TOKEN used by openAIP.net site in order to load their custom styles for the map
const OPEN_AIP_MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoid2VibWFzdGVyLW9wZW5haXAiLCJhIjoiY2x3MDk4ajdmMzRuazJrb2RodGs1M3RiZSJ9.oBnOUp8plNDs9Ef8l8jCeg';

const BASIC_NOISE_ZOOM = 11;

const { BaseLayer, Overlay } = LayersControl;

const LeafletMap = ({
  onViewportChanged,
  className,
  mapType,
  mapWeatherRadarType,
  zoomBounds,
  viewport,
  dragging = true,
  scrollWheelZoom = true,
  boxZoom = true,
  doubleClickZoom = true,
  noiseData = null,
  renderAircraftOverlay,
  renderGeofences,
  attributes,
}) => {
  const dispatch = useDispatch();
  const mapInstance = useRef();
  const handleViewportChanged = (newViewport) => {
    const map = mapInstance?.current?.leafletElement;
    if (map) {
      newViewport.bounds = getPlainBounds(map.getBounds());
    }
    if (onViewportChanged) onViewportChanged(newViewport);
  };

  const [openAIPMapStyle, setOpenAIPMapStyle] = useState();
  useEffect(() => {
    if (openAIPMapStyle === undefined) {
      const savedToSession = sessionStorage.getItem('openAIPMapStyle');
      if (savedToSession) {
        setOpenAIPMapStyle(JSON.parse(savedToSession));
      } else {
        axios
          .get('https://api.tiles.openaip.net/api/styles/openaip-default-style.json')
          .then((result) => {
            setOpenAIPMapStyle(result.data);
            sessionStorage.setItem('openAIPMapStyle', JSON.stringify(result.data));
          })
          .catch((error) => {
            setOpenAIPMapStyle(null);
            dispatch(makeUnavailableLayer('airspacesLayer'));
            dispatch(makeUnavailableLayer('airportsLayer'));
          });
      }
    }
  }, [dispatch, openAIPMapStyle]);

  useEffect(() => {
    const setMapTileScale = () => {
      let scale = 1;
      if (window.devicePixelRatio < 1) {
        scale = 1 + 1 / window.devicePixelRatio / 100;
      }
      if (window.devicePixelRatio > 1) {
        scale = 1.0045;
      }
      document.documentElement.style.setProperty('--tile-scale', scale);
    };

    window.addEventListener('resize', setMapTileScale);
    setMapTileScale();

    return () => {
      window.removeEventListener('resize', setMapTileScale);
    };
  }, []);

  const handleMapClick = (e) => {
    const clickTargetClasses = [...e.originalEvent.target.classList];
    const isFlightTag = clickTargetClasses.indexOf('tag_wrapper') >= 0;
    const isPoint = clickTargetClasses.indexOf('map-flight') >= 0;
    const isFlightpath = clickTargetClasses.indexOf('map-flightpath') >= 0;

    const shouldDiscardSelection = !isFlightTag && !isPoint && !renderAircraftOverlay && !isFlightpath;
    if (shouldDiscardSelection) {
      noiseData.setChosenNoiseFlight(null);
      noiseData.setFlightTagData(null);
    }
  };
  const { airspacesLayer, airportsLayer, dimmingLayer } = attributes;

  const [loadMapboxLayers, setLoadMapboxLayers] = useState(false);
  useEffect(() => {
    if (mapType === DARK || airspacesLayer || airportsLayer) {
      setLoadMapboxLayers(true);
    }
  }, [mapType, airspacesLayer, airportsLayer]);

  const renderRectangePlaceholder = (isBlack) => {
    return (
      <Rectangle
        color={isBlack ? '#343332' : '#FFFFFF'}
        bounds={[
          [90, -270],
          [-90, 270],
        ]}
        fillOpacity={isBlack ? 1 : 0}
      />
    );
  };

  return (
    <Map
      className={clsx(className, `map_type_${mapType}`, {
        'map_radar-legend': mapWeatherRadarType,
      })}
      zoomControl={false}
      onViewportChanged={handleViewportChanged}
      viewport={viewport}
      bounds={zoomBounds}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      ref={mapInstance}
      dragging={dragging}
      scrollWheelZoom={scrollWheelZoom}
      boxZoom={boxZoom}
      doubleClickZoom={doubleClickZoom}
      whenReady={() => {
        const map = mapInstance?.current?.leafletElement;
        if (map) {
          const centerLatLng = map.getCenter();
          if (onViewportChanged) {
            onViewportChanged({
              center: [centerLatLng.lat, centerLatLng.lng],
              zoom: map.getZoom(),
              bounds: getPlainBounds(map.getBounds()),
            });
          }
        }
      }}
      onClick={handleMapClick}
      minZoom={1}
      maxZoom={19}
    >
      <LayersControl position="bottomleft">
        {[TERRAIN, ROADMAP, SATELLITE, HYBRID].map((type) => (
          <BaseLayer checked={mapType === type} name={type} key={type}>
            <ReactLeafletGoogleLayer
              useGoogMapsLoader={false}
              type={baseLayersToGoogleMutantType[type]}
              maxNativeZoom={19}
              maxZoom={21}
              styles={[
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
              ]}
            />
          </BaseLayer>
        ))}

        <BaseLayer checked={[OSM, IFRL, IFRH, VFR].some((v) => v === mapType)} name="Open Street Map">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>

        <BaseLayer checked={mapType === DARK && loadMapboxLayers} name="Dark Map">
          {loadMapboxLayers ? (
            <Suspense fallback={renderRectangePlaceholder(true)}>
              <MapBoxGLLayer accessToken={MAPBOX_ACCESS_TOKEN} mapStyle="mapbox://styles/mapbox/dark-v10" />
            </Suspense>
          ) : (
            renderRectangePlaceholder(true)
          )}
        </BaseLayer>
        <Overlay name="VFR" checked={mapType === VFR}>
          <TileLayer url="https://tile-proxy.vtt.systems/20240711/tiles/vfrc/{z}/{y}/{x}.jpg" maxNativeZoom={11} tms />
        </Overlay>
        <Overlay name="IFRL" checked={mapType === IFRL}>
          <TileLayer url="https://tile-proxy.vtt.systems/20240711/tiles/ifrlc/{z}/{y}/{x}.jpg" maxNativeZoom={11} tms />
        </Overlay>
        <Overlay name="IFRH" checked={mapType === IFRH}>
          <TileLayer url="https://tile-proxy.vtt.systems/20240711/tiles/ehc/{z}/{y}/{x}.jpg" maxNativeZoom={10} tms />
        </Overlay>

        {mapWeatherRadarType && (
          <Overlay name="NOOA Weather Radar" checked>
            <WeatherRadar radarType={mapWeatherRadarType} />
          </Overlay>
        )}
        <Overlay name="warnings" checked={mapWeatherRadarType}>
          <WMSTileLayer
            url="https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows"
            layers="warnings"
            format="image/png"
            transparent
            uppercase
          />
        </Overlay>

        {/* OTHER LAYERS */}
        <Overlay name="Airports & Airspace" checked={airspacesLayer || airportsLayer}>
          {loadMapboxLayers ? (
            <Suspense fallback={renderRectangePlaceholder()}>
              <OpenAIPMapboxLayer
                accessToken={OPEN_AIP_MAPBOX_ACCESS_TOKEN}
                style={openAIPMapStyle}
                layers={{ airspacesLayer, airportsLayer }}
              />
            </Suspense>
          ) : (
            renderRectangePlaceholder()
          )}
        </Overlay>

        {renderAircraftOverlay && renderAircraftOverlay()}
      </LayersControl>
      <ScaleControl position="bottomleft" />
      <Rectangle
        className="dimming-layer"
        color="#000000"
        weight={0}
        bounds={[
          [90, -270],
          [-90, 270],
        ]}
        fillOpacity={dimmingLayer ? 0.3 : 0}
      />
      {renderGeofences && renderGeofences()}
      {noiseData && (
        <Noise
          noiseData={noiseData}
          zoomLevel={mapInstance?.current?.leafletElement?.getZoom?.() || BASIC_NOISE_ZOOM}
        />
      )}
    </Map>
  );
};

LeafletMap.propTypes = {
  onViewportChanged: PropTypes.func,
  className: PropTypes.string,
  mapType: PropTypes.oneOf(baseLayers),
  mapWeatherRadarType: PropTypes.oneOf(weatherRadarLayerDetailsList.map((d) => d.type)),
  zoomBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  viewport: PropTypes.exact({
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    bounds: PropTypes.shape({
      vpn: PropTypes.number,
      vps: PropTypes.number,
      vpw: PropTypes.number,
      vpe: PropTypes.number,
    }),
  }),
  dragging: PropTypes.bool,
  scrollWheelZoom: PropTypes.bool,
  boxZoom: PropTypes.bool,
  doubleClickZoom: PropTypes.bool,
  noiseData: NoiseAbatementPropType,
  renderAircraftOverlay: PropTypes.func,
  renderGeofences: PropTypes.func,
  attributes: PropTypes.shape({
    tagsLayer: PropTypes.bool,
    turnedOffLayer: PropTypes.bool,
    sensorsLayer: PropTypes.bool,
    weatherLayer: PropTypes.bool,
    geofenceLayer: PropTypes.bool,
    airspacesLayer: PropTypes.bool,
    dimmingLayer: PropTypes.bool,
  }),
};

export * from './baseLayers';
export default LeafletMap;
