import axios from 'axios';
import cookie from 'utils/cookies';

function getAuthorizationHeaders() {
  const headers = {};

  const token = cookie.getToken();
  if (token) {
    Object.assign(headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  return headers;
}

const handleErrorCode = (code) => {
  // handle auth rejected
  if (code === 401) {
    if (window.location.pathname !== '/' && window.location.pathname !== '' && window.location.pathname !== '/totp') {
      window.location.href = window.location.origin;
    }
  }
};

const createFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== null) formData.append(key, data[key]);
  });

  return formData;
};

const axiosAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api` : '/api',
  timeout: 60000,
});

axiosAPIInstance.interceptors.request.use(
  (config) => {
    const headers = getAuthorizationHeaders();
    Object.assign(config.headers, headers);
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAPIInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const data = error?.response?.data;
    error.message = data?.message || error.message;
    handleErrorCode(data?.statusCode);

    return Promise.reject(error);
  }
);

class API {
  constructor(axiosInstance) {
    this.axios = axiosInstance;
  }

  async logIn(logInDTO) {
    const response = await this.axios.post('/auth/login', logInDTO);
    return response.data;
  }

  async loginAs(username) {
    const response = await this.axios.post('/auth/login-as', { username });
    return response.data;
  }

  async loginTotp(totpToken) {
    const response = await this.axios.post('/auth/totp', { totpToken });

    return response.data;
  }

  async logout() {
    const response = await this.axios.post('/auth/logout');
    return response.data;
  }

  async getCurrentUser() {
    const response = await this.axios.get('/users/current');
    return response.data;
  }

  async getCustomers() {
    const response = await this.axios.get('/customers');
    return response.data;
  }

  async getCustomerById(id) {
    const response = await this.axios.get(`/customers/${id}`);
    return response.data;
  }

  async createCustomer(customer) {
    const response = await this.axios({
      method: 'post',
      url: `/customers/`,
      data: createFormData(customer),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  async updateCustomer(customer) {
    const response = await this.axios({
      method: 'post',
      url: `/customers/${customer.id}`,
      data: createFormData(customer),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  async updateCustomerSettings(customer) {
    const response = await this.axios({
      method: 'patch',
      url: `/customers/settings`,
      data: createFormData(customer),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  async updateCustomerMetaf(customer) {
    const response = await this.axios({
      method: 'patch',
      url: `/customers/metaf`,
      data: customer,
    });
    return response.data;
  }

  async deleteCustomer(id) {
    const response = await this.axios.delete(`/customers/${id}`);
    return response.data;
  }

  /**
   * @typedef {Object} CustomerGroup
   * @property {number} id
   * @property {string} name
   */

  /**
   * Get the full list of currently available customer groups
   * @returns {Promise<CustomerGroup[]>} List of customer groups with their IDs
   */
  async getCustomerGroups() {
    const response = await this.axios.get('/customers/groups');
    return response.data;
  }

  async batchUpdateCustomerGroup(data) {
    const response = await this.axios.post(`/customers/batch`, data);
    return response.data;
  }

  async getAirportLogins() {
    const response = await this.axios.get('/customers/service-users');
    return response.data;
  }

  async getUsers() {
    const response = await this.axios.get('/users');
    return response.data;
  }

  async getUserById(id) {
    const response = await this.axios.get(`/users/${id}`);
    return response.data;
  }

  async createUser(user) {
    const response = await this.axios.post('/users', user);
    return response.data;
  }

  async updateUser(user) {
    const response = await this.axios.put(`/users/${user.id}`, user);
    return response.data;
  }

  async deleteUser(id) {
    const response = await this.axios.delete(`/users/${id}`);
    return response.data;
  }

  async getBasedAircraftList() {
    const response = await this.axios.get('/aircraft/current', {
      timeout: 180000,
    });
    return response.data;
  }

  async getAircraftDBList(paginationParams) {
    const { limit, offset, sort, filter = {} } = paginationParams;
    const queryParams = new URLSearchParams();

    if (limit) {
      queryParams.append('limit', limit);
    }

    if (offset) {
      queryParams.append('offset', offset);
    }

    if (typeof sort?.desc !== 'undefined') {
      queryParams.append('sort', `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`);
    }

    Object.entries(filter).forEach(([key, value]) => {
      if (value) queryParams.append('filter', `${key}:${value}`);
    });

    const response = await this.axios.get(`/aircraft?${queryParams.toString()}`, {
      timeout: 180000,
    });

    return response.data;
  }

  async getAircraftById(id) {
    const response = await this.axios.get(`/aircraft/${id}`);
    return response.data;
  }

  async updateAircraft(aircraft) {
    const response = await this.axios({
      method: 'patch',
      url: `/aircraft/${aircraft.id}`,
      data: createFormData(aircraft),
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }

  async archiveAircraft(id) {
    const response = await this.axios.patch(`/aircraft/archive/${id}`);
    return response.data;
  }

  async removeBasedAircraft(data) {
    const response = await this.axios.post(`/aircraft/remove`, data);
    return response.data;
  }

  async batchUpdateAircraftsCategory(data) {
    const response = await this.axios.patch(`/aircraft`, data);
    return response.data;
  }

  /**
   * @typedef {Object} Geofence
   * @property {number} id
   * @property {boolean} active
   * @property {string} name
   * @property {string} remarks
   * @property {number} lat1
   * @property {number} lon1
   * @property {string} remark1
   * @property {number} lat2
   * @property {number} lon2
   * @property {string} remark2
   * @property {number} lat3
   * @property {number} lon3
   * @property {string} remark3
   * @property {number} lat4
   * @property {number} lon4
   * @property {string} remark4
   * @property {number} lowerAlt
   * @property {number} upperAlt
   */

  /**
   * Fetch list of all geofences
   * @returns {Promise<Geofence[]>} List of geofences
   */
  async getGeofences(params = {}) {
    const queryParams = new URLSearchParams(params);
    const { bounds = {} } = params;
    if (Object.keys(bounds).length) {
      const { vpn = 1, vpe = 1, vps = 1, vpw = 1 } = bounds;
      queryParams.append('vpn', vpn);
      queryParams.append('vps', vps);
      queryParams.append('vpw', vpw);
      queryParams.append('vpe', vpe);
    }
    const response = await this.axios.get(`/geofence?${queryParams.toString()}`);
    return response.data;
  }

  /**
   * Fetch geofence by id
   * @param {number} geofenceId - Geofence id to fetch
   * @returns {Promise<Geofence>} Geofence
   */
  async getGeofence(geofenceId) {
    const response = await this.axios.get(`/geofence/${geofenceId}`);
    return response.data;
  }

  /**
   * Create new geofence
   * @param {Geofence} geofence - Initial values of geofence
   * @returns {Promise<Geofence>} Created geofence
   */
  async createGeofence(geofence) {
    const response = await this.axios.post('/geofence', geofence);
    return response.data;
  }

  /**
   * Update existing geofence
   * @param {Geofence} geofence - Updated values of geofence
   * @returns {Promise<void>}
   */
  async updateGeofence(geofence) {
    const response = await this.axios.put(`/geofence/${geofence.id}`, geofence);
    return response.data;
  }

  /**
   * Delete existing geofence
   * @param {number} geofenceId - Geofence id to delete
   * @returns {Promise<void>}
   */
  async deleteGeofence(geofenceId) {
    const response = await this.axios.delete(`/geofence/${geofenceId}`);
    return response.data;
  }

  /**
   * @typedef {Object} Activity
   * @property {number} id
   * @property {boolean} active
   * @property {number} customerId
   * @property {string} name
   * @property {string} remarks
   * @property {string[]} sourcePriority
   * @property {object} activityLogic
   * @property {string} eventName
   * @property {string} eventColor
   * @property {boolean} isAlarmListNotification
   * @property {boolean} isModalOnMapNotification
   * @property {boolean} isEmailNotification
   * @property {boolean} isShownOnFlights
   */

  /**
   * Fetch list of all activities
   * @returns {Promise<Activity[]>} List of activities
   */
  async getActivities() {
    const response = await this.axios.get('/activities');
    return response.data;
  }

  /**
   * Fetch activity by id
   * @param {number} activityId - Activity id to fetch
   * @returns {Promise<Activity>} Activity
   */
  async getActivity(activityId) {
    const response = await this.axios.get(`/activities/${activityId}`);
    return response.data;
  }

  /**
   * Create new activity
   * @param {Activity} activity - Initial values of activity
   * @returns {Promise<Activity>} Created activity
   */
  async createActivity(activity) {
    const response = await this.axios.post('/activities', activity);
    return response.data;
  }

  /**
   * Update existing activity
   * @param {Activity} activity - Updated values of activity
   * @returns {Promise<void>}
   */
  async updateActivity(activity) {
    const response = await this.axios.put(`/activities/${activity.id}`, activity);
    return response.data;
  }

  /**
   * Update partially existing activity
   * @param {Activity} activityDto - Updated values of activity
   * @returns {Promise<void>}
   */
  async patchActivity(activityDto) {
    await this.axios.patch(`/activities/${activityDto.id}`, activityDto);
    return activityDto;
  }

  /**
   * Delete existing activity
   * @param {number} activityId - Activity id to delete
   * @returns {Promise<void>}
   */
  async deleteActivity(activityId) {
    const response = await this.axios.delete(`/activities/${activityId}`);
    return response.data;
  }

  async getActivityDetails() {
    const response = await this.axios.get(`/activities/details`);
    return response.data;
  }

  async getAuditLog() {
    const response = await this.axios.get(`/audit-log`);
    return response.data;
  }

  async saveAuditLog(auditLog) {
    await this.axios.post(`/audit-log`, auditLog);
    return auditLog;
  }

  /**
   * Get all operations for current customer
   * @param {*} query contains options from & to datetime limitations
   * @returns {Promise<{data: Object, meta: Object}>}
   */

  async getOperations(paginationParams) {
    const { limit = 100, offset, sort, filter = {}, timezoneName, isDebug } = paginationParams;
    const queryParams = new URLSearchParams();

    if (limit) {
      queryParams.append('limit', limit);
    }

    if (offset) {
      queryParams.append('offset', offset);
    }

    if (typeof sort?.desc !== 'undefined') {
      queryParams.append('sort', `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`);
    }

    Object.entries(filter).forEach(([key, value]) => {
      if (value) queryParams.append('filter', `${key}:${value}`);
    });

    if (timezoneName) {
      queryParams.append('timezoneName', timezoneName);
    }

    if (isDebug) {
      queryParams.append('isDebug', true);
    }

    const response = await this.axios.get('/operations', { params: queryParams, timeout: 0 });
    return response.data;
  }

  /**
   * Retrieves flight information based on activity for the specified period.
   *
   * @param {Object} params - The parameters for the request.
   * @param {string} params.from - The start date ISO of the period.
   * @param {string} params.to - The end date ISO of the period.
   * @returns {Promise<Object>} The flight data per activity.
   */
  async getOperationsFlightsPerActivities(query) {
    const { from, to } = query;
    const queryParams = new URLSearchParams();

    queryParams.append('from', from);
    queryParams.append('to', to);

    const response = await this.axios.get('/operations/flights-per-activity', {
      params: queryParams,
      timeout: 0,
    });
    return response.data;
  }

  /**
   * Get all operations for current customer and specified id
   * @returns {Promise<{data: Object, meta: Object}>}
   */
  async getOperationsById(id) {
    const response = await this.axios.get(`/operations/${id}`);
    return response.data;
  }

  async deleteOperations(data) {
    const response = await this.axios.delete('/operations', { data });
    return response.data;
  }

  /**
   *
   * @param {Object} noiseAbatementDto {dateStart, dateEnd, registrationQuery, flightQuery}
   */
  async getNoiseAbatementData(noiseAbatementDto) {
    const response = await this.axios.post('/noise-abatement', noiseAbatementDto, {
      timeout: 0,
    });
    return response.data;
  }

  async getReportsData() {
    const response = await this.axios.get('/reports/data');
    return response.data;
  }

  async createReport(data) {
    const response = await this.axios.post('/reports', data, {
      timeout: 0,
    });
    return response.data;
  }

  async getCustomerGroupsReport(customerGroupId) {
    const response = await this.axios.get(`/reports/customer-group/${customerGroupId}`, {
      timeout: 0,
      responseType: 'blob',
    });
    return response;
  }

  async getReport5010(query) {
    const queryParams = new URLSearchParams(query);
    const response = await this.axios.get(`/reports/5010?${queryParams.toString()}`);
    return response.data;
  }

  async getExternalDBStatus() {
    const response = await this.axios.get('/external-db');
    return response.data;
  }

  async startSyncing() {
    const response = await this.axios.post('/external-db/sync');
    return response.data;
  }

  /**
   *
   * @param {Object} laddForm - LADD upload form values
   * @param {File} faaForm.file - LADD txt list
   */
  async uploadLADD(laddForm) {
    const response = await this.axios({
      method: 'post',
      url: `/external-db/ladd`,
      data: createFormData(laddForm),
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0,
    });
    return response.data;
  }

  /**
   *
   * @param {Object} faaForm - FAA upload form values
   * @param {File} faaForm.file - FAA DB archive ReleasableAircraft.zip
   */
  async updateFaaDB(faaForm) {
    const response = await this.axios({
      method: 'post',
      url: `/external-db/faa`,
      data: createFormData(faaForm),
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0,
    });
    return response.data;
  }

  /**
   *
   * @param {string} sourceToSync source name to sync with (faa)
   */
  async syncFlightDB() {
    const response = await this.axios({
      method: 'post',
      url: `/external-db/sync`,
      timeout: 0,
    });
    return response.data;
  }

  /**
   *
   * @param {Object} basedAircraftForm - Based Aircraft import form values
   * @param {File} basedAircraftForm.file - Based Aircraft import file txt or csv
   */
  async importBasedAircraft(basedAircraftForm) {
    const response = await this.axios({
      method: 'post',
      url: `/external-db/based`,
      data: createFormData(basedAircraftForm),
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0,
    });
    return response.data;
  }

  /**
   * Get all sensors for current customer
   * @returns {Promise<{dat: string, TLS: [Object]}>}
   */
  async getSensors() {
    const response = await this.axios.get('/proxy/clients');
    return response.data;
  }
  /**
   * @typedef {Object} Settings
   * @property {number} id 1
   * @property {string} lang en
   * @property {string} copy '©2021',
   * @property {string} supportText 'We are help to help.  # Welcome',
   * @property {string} version 'Version  1.0.0',
   * @property {string} reportAddress 'VirTower LLC',
   * @property {number} noiseAbatementInterval 5,
   */

  /**
   * Fetch system settings
   * @returns {Promise<Settings>} Settings
   */
  async getSettings() {
    const response = await this.axios.get('/settings');
    return response.data;
  }

  /**
   * Update system settings
   * @param {Settings} settings - Updated values of settings
   * @returns {Promise<void>}
   */
  async updateSettings(settings) {
    const response = await this.axios.post('/settings', settings);
    return response.data;
  }

  async getStats(path, params) {
    const queryParams = new URLSearchParams(params);
    const response = await this.axios.get(`/statistic/${path}?${queryParams.toString()}`);

    return response.data;
  }
}

const api = new API(axiosAPIInstance);

export { API, axiosAPIInstance };
export default api;
