const MAP_CONTROLS = {
  FLIGHTS: 'flights',
  MAP_LAYERS: 'layers',
  ZOOM_PRESET: 'zoomPreset',
  FILTERS: 'filters',
};

const MODALS = {
  ALARMS: 'modal-alarms',
  MAP_LAYERS: 'modal-map-layers',
  ZOOM_PRESET: 'modal-zoom-preset',
  FLIGHTS: 'modal-flights',
  FILTERS: 'modal-filters',
};

const TABLE_FILTER_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
  DATE: 'customDate',
  TIMESTAMP: 'timestamp',
};

const TABLE_BUTTON_TYPES = {
  EDIT: 'edit',
  ADD: 'add',
  ARCHIVE: 'archive',
  DELETE: 'delete',
};

const TABLE_PAGE_SIZE = 100;
const MAX_SECONDS_BETWEEN_FLIGHT_POINTS = 20;

export const TIS_B_CLA_PARAMETERS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  TENTH: 10,
  ELEVENTH: 11,
};

export const UAT_CLA_PARAMETERS = {
  FOURTH: 4,
  EIGHTH: 8,
  TWELVE: 12,
};

export {
  MAP_CONTROLS,
  MODALS,
  TABLE_FILTER_TYPES,
  TABLE_BUTTON_TYPES,
  TABLE_PAGE_SIZE,
  MAX_SECONDS_BETWEEN_FLIGHT_POINTS,
};
