import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, resetFilters } from 'redux/modules/map';
import { MODALS } from 'utils/globalConstants';
import DNDModal from 'components/base/DNDModal';
import CustomInput from 'components/base/CustomInput';
import CustomSelect from 'components/base/CustomSelect';
import Button from 'components/base/Button';

import './FiltersModal.css';

const FiltersModal = ({ top = 0, left = 0, width = 350, height }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.map);
  const { isDebugMode } = useSelector((state) => state.auth.userInfo);
  const filtersKeyFilteredByDebugMode = Object.keys(filters).filter((key) => {
    const { isDebugFilter } = filters[key];
    return isDebugFilter ? isDebugMode : true;
  });

  return (
    <DNDModal id={MODALS.FILTERS} left={left} top={top} title="Filter" width={width} height={height}>
      <div className="map-filters">
        {filtersKeyFilteredByDebugMode.map((key) => {
          const { alias, value, options, divider, placeholder } = filters[key];

          return (
            <React.Fragment key={alias}>
              <div className="map-filters_row">
                <div className="map-filters_formfield">
                  {!options &&
                    value.map((val, index) => {
                      return (
                        <CustomInput
                          // eslint-disable-next-line react/no-array-index-key
                          key={alias + index}
                          value={val}
                          onChange={(e) => {
                            dispatch(
                              setFilter({
                                filter: key,
                                valueIndex: index,
                                value: e.target.value,
                              })
                            );
                          }}
                          placeholder={placeholder[index]}
                          className="map-filters__input"
                        />
                      );
                    })}
                  {options && (
                    <CustomSelect
                      name={alias}
                      options={options}
                      value={value[0]}
                      className="map-filters__input"
                      onChange={(val) => {
                        dispatch(
                          setFilter({
                            filter: key,
                            valueIndex: 0,
                            value: val,
                          })
                        );
                      }}
                    />
                  )}
                </div>
                <div className="map-filters_alias">{alias}</div>
              </div>
              {divider && <div className="dndmodal__divider" />}
            </React.Fragment>
          );
        })}
        <Button
          mods={{ action: true }}
          className="map-filters_button"
          type="button"
          onClick={() => {
            dispatch(resetFilters());
          }}
        >
          Clear Filters
        </Button>
      </div>
    </DNDModal>
  );
};

FiltersModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FiltersModal;
